import React from 'react'
import Header from "./components/Header"
import Footer from "./components/Footer"
import Container from 'react-bootstrap/Container'
import seo from './helpers/seo'

class About extends React.Component {

  componentDidMount() {
    seo({
      title: 'About'
    });
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <Container className="content">
          <div>
             <img alt="Barber Pole at The Olde Barber" src="/images/barber-pole.jpg" />
            
            <h1 className="pt-5">Passing the barber pole</h1>

            
            <h3 className="pb-3 pt-3 text-muted">Before <i>The Olde Barber Cannabis Co.</i>, this quaint little shop was home to the town's first barber shop and then, ironically, a barber shop for our furry friends.</h3>

            <p>Many locals have fond memories of their very first (and last) hair cuts with “Cam Eno”, noted to be the best barber in town back in the 80s.</p>

            <p>Fast forward to 2022, the small, rundown building was transformed into <i>The Olde Barber Cannabis Co.</i>, named to pay homage to the building's history. Our small cannabis retail shop is located in Cobalt, a place designated as Ontario's most historic town. If you have ever been to Cobalt, you would know that we are famous for all of our head frames, mine shafts, and tunnels located underneath our beautiful forests as well as our urban areas, including our downtown centre. Cobalt was notably responsible for most of the world's Silver, producing over 30 million ounces by 1911, skyrocketing Canada's economy.</p>

            <p>Take a step back in time when you enter this thoughtfully curated shop with a vintage barber-style theme. All of our employees have obtained expert-level certifications, are active cannabis consumers, and are just as passionate about education as recreation. We are proudly knowledgeable about all of the products and accessories that we sell and we provide a range of sustainable and vegan-friendly products, including all topicals and edibles.</p>

            <p>We are located on Highway 11B, connecting Cobalt to Haileybury and New Liskeard. Take the scenic route on your next vacation or snowmobiling trip, and remember to visit us at <a target="_blank" rel="noreferrer" href="https://www.instagram.com/the_olde_barber/"><i>The Olde Barber</i></a> and inhale something beautiful every day!</p>

            <p>
            The Olde Barber Cannabis Co.<br/>
            33 Silver St.<br/>
            Cobalt, ON<br/>
            P0J 1C0
            </p>

            <p>Email us: <a href="mailto:theoldebarber@gmail.com">theoldebarber@gmail.com</a></p>

          </div>
        </Container>
        <Footer />
      </React.Fragment>
    )
  }

}

export default About